<template>
  <v-container grid-list-xs class="mt-12">
    <h3
      class="text-center"
      style="font-family: Spartan; font-size: 14px; line-height: 16px"
    >
      {{ whatTheySay }}
    </h3>
    <h2
      class="text-center mb-4"
      style="font-family: Playfair Display; font-size: 48px; line-height: 58px"
    >
      {{ testimonialsText }}
    </h2>
    <v-row justify="center" align="center">
      <v-carousel
        hide-delimiters
        continuous
        cycle
        :show-arrows="$vuetify.breakpoint.mobile ? false : true"
      >
        <v-carousel-item
          v-for="testimony in testimonials"
          :key="testimony.author"
        >
          <v-row tile class="fill-height" justify="center" align="center">
            <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 8">
              <v-card class="mx-auto" color="primary" dark tile>
                <v-card-text>
                  <p class="white--text">
                    {{ testimony.quote }}
                  </p>
                </v-card-text>

                <v-card-text>
                  <v-list-item>
                    <v-list-item-avatar color="white ">
                      <v-img
                        class="elevation-8"
                        alt="avatar"
                        :src="testimony.avatar"
                      ></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>{{
                        testimony.author
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-carousel-item>
      </v-carousel>
      <v-btn
        tile
        class="primary text-capitalize"
        flat
        large
        style="
          font-family: Playfair Display;
          font-size: 20px;
          font-weight: 700;
          line-height: 29px;
        "
        @click="goToReviews"
        >{{ viewMoreText }}</v-btn
      >
    </v-row></v-container
  >
</template>

<script>
export default {
  name: "Testimonials",

  data() {
    return {
      whatTheySay: "WHAT THEY SAY ABOUT US",
      testimonialsText: "Testimonials",
      viewMoreText: "View More",
      reviewsUrl:
        "https://www.google.com/travel/hotels/entity/CgsIyOKRi6yslIXrARAB/reviews?g2lb=2502548%2C2503771%2C2503781%2C4258168%2C4270442%2C4306835%2C4317915%2C4371334%2C4401769%2C4419364%2C4509341%2C4515404%2C4545890%2C4561952%2C4596364%2C4599968%2C4270859%2C4284970%2C4291517&hl=en-TZ&gl=tz&cs=1&ssta=1&rp=EMjikYusrJSF6wEQyOKRi6yslIXrATgCQABIAcABAg&ictx=1&sa=X&ved=0CAAQ5JsGahcKEwi4x9vvjKnyAhUAAAAAHQAAAAAQBg&utm_campaign=sharing&utm_medium=link&utm_source=htls&ts=CAESABpJCisSJzIlMHgxOWNlN2IzNTVkYjJjNGM5OjB4ZWIwYTUxNjJjMTY0NzE0OBoAEhoSFAoHCOUPEAkYAhIHCOUPEAkYAxgBMgIQACoJCgU6A1RaUxoA",
      testimonials: [
        {
          author: "Vishal K Seth",
          avatar:
            "https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light",
          quote:
            "Large comfortable rooms, very friendly staff. Nice view of Lake Victoria from room. Food is also good. Overall Satisfactory experience",
        },
        {
          author: "Vic Amani",
          avatar:
            "https://avataaars.io/?avatarStyle=Transparent&topType=NoHair&accessoriesType=Blank&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=BlazerShirt&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Brown",
          quote:
            "Overnight on business. Older hotel: they understand hospitality. Spacious, airy, very very quiet. Gourgeous views of Isamilo Hills and Lake Victoria on the horizon. Exceptional breakfast (inclusive). Plushest towels in Mwanza. Wish room and restaurant service, and wi-fi, was faster. VERDICT: Would definitely stay there on my next visit.",
        },
        {
          author: "Marie Maembe (Mimi ni Marie)",
          avatar:
            "https://avataaars.io/?avatarStyle=Transparent&topType=LongHairStraight&accessoriesType=Blank&hairColor=BrownDark&facialHairType=Blank&clotheType=BlazerShirt&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Light",
          quote:
            "Love this hotel in Mwanza! The staff is very kind and helpful. Rooms are very spacious, clean and contain everything you need (incl coffee/tea amenities). The pool and poolbar are great places to relax and enjoy. Breakfast is delicious with a variety of Tanzanian and European food. The restaurant, especially Indian dishes, serves delicious food. During the weekends it is possible to hear some sounds from the club down below, but to me it was never disturbing. During the week it is usually very quiet. The neighborhood is very safe and you can easily go for a walk during the day. It is situated on the hills of Isamilo, which makes the view from the rooms amazing!",
        },
        {
          author: "Ralph Chegeni",
          avatar:
            "https://avataaars.io/?avatarStyle=Transparent&topType=LongHairFro&accessoriesType=Wayfarers&hairColor=BrownDark&facialHairType=Blank&clotheType=GraphicShirt&clotheColor=Blue02&graphicType=Diamond&eyeType=Happy&eyebrowType=DefaultNatural&mouthType=Smile&skinColor=Tanned",
          quote:
            "Isamilo Lodge & Spa has been a home away from from. Most of us who stay there we experience the hospitality of high level and warm treatment from the staffs. Once you plan your way to Mwanza think of the beautiful and serenity place to stay at Isamilo",
        },
        {
          author: "Josephine Kiiza",
          avatar:
            "https://avataaars.io/?avatarStyle=Transparent&topType=LongHairBob&accessoriesType=Blank&hairColor=Red&facialHairType=Blank&clotheType=GraphicShirt&clotheColor=Gray01&graphicType=Diamond&eyeType=Default&eyebrowType=RaisedExcited&mouthType=Twinkle&skinColor=Light",
          quote:
            "Accommodation located at the top of the rocks with a beautiful view of the city. The rooms are large, clean and self contained. Guests are well served. Most importantly it's a quiet place best for relaxation after work, long journey and adventure Safaris",
        },
      ],
    };
  },
  methods: {
    goToReviews() {
      window.open(this.reviewsUrl);
    },
  },
};
</script>

