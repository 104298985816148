<template>
  <v-overlay color="white" opacity="1" :value="showDrawer">
    <v-container grid-list-xs>
      <v-row justify="center">
        <v-img
          alt="Logo"
          contain
          min-width="100"
          src="@/assets/logo.png"
          width="140"
          @click="goTo('/')"
        />
      </v-row>
      <v-row justify="center" v-for="button in buttons" :key="button.key">
        <v-btn
          plain
          light
          class="center-text"
          style="font-family: Spartan; font-size: 14px; line-height: 16px"
          @click="goTo(button.key)"
          >{{ button.label }}</v-btn
        >
      </v-row>
      <v-row justify="center">
        <v-btn
          tile
          depressed
          large
          color="primary"
          style="
            font-family: Spartan;
            font-size: 14px;
            line-height: 16px;
            font-weight: 700;
          "
          @click="goTo('Booknow')"
          >BOOK NOW</v-btn
        >
      </v-row>
    </v-container>
  </v-overlay>
</template>

<script>
export default {
  name: "Drawer",
  props: { showDrawer: Boolean },
  data() {
    return {
      buttons: [
        {
          label: "HOME",
          key: "/",
        },
        {
          label: "ROOMS",
          key: "Rooms",
        },
        {
          label: "EVENTS",
          key: "Events",
        },
        {
          label: "TOURS",
          key: "Tours",
        },
        {
          label: "ABOUT",
          key: "About",
        },
        {
          label: "GALLERY",
          key: "Gallery",
        },
      ],
    };
  },
  methods: {
    onCloseDrawer() {
      console.log("on close drawer");
      this.$emit("close");
    },
    goTo(path) {
      this.$router.push(path);
      this.$emit("close");
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style>
</style>