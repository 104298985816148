<template>
  <div id="events">
    <Hero :title="title" :subtitle="subtitle" :src="src" />
    <v-container grid-list-xs class="pt-12">
      <v-row justify="center">
        <v-col
          cols="auto"
          sm="6"
          lg="6"
          v-for="event in events"
          :key="event.title"
        >
          <v-card tile elevation="8" height="100%">
            <v-img height="400" :src="event.image"></v-img>
            <v-card-title
              primary-title
              style="
                font-family: Playfair Display;
                font-size: 28px;
                font-weight: 700;
                line-height: 58px;
              "
            >
              {{ event.title }}
            </v-card-title>
            <v-card-text
              style="
                font-family: Spartan;
                font-size: 16px;
                line-height: 32px;
                color: #000;
              "
            >
              {{ event.description }}
            </v-card-text>

            <v-btn
              elevation="0"
              dark
              tile
              block
              color="primary"
              class="text-center pa-2 text-capitalize"
              style="
                font-family: Playfair Display;
                font-size: 20px;
                font-weight: 700;
                line-height: 29px;
              "
              @click="booknow"
            >
              {{ cta }}
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Hero from "@/components/layouts/Hero.vue";
export default {
  name: "Events",
  components: { Hero },
  data() {
    return {
      src: require("@/assets/events_1.jpg"),
      title: "SPECIAL EVENTS HAPPENING",
      subtitle: "Events",

      events: [
        {
          title: "The Swiming Pool",
          description:
            "Would you like to swim in the swimming pool during your stay in Mwanza? Would you like to wake up, take a swim or just refresh yourself or have fun with your children? Then we can offer accommodation with a swimming pool in Mwanza.",
          image: require("@/assets/events_2.jpg"),
        },
        {
          title: "The Sauna",
          description:
            "Enjoy the healthy heat in the sauna. The sauna feels like paradise, especially after an active day in the natural environment in Mwanza. While your muscles deeply relax your mind and you will also feel how relaxation is taking over.",
          image: require("@/assets/isamilo_lodge_gallery_39.jpeg"),
        },
        {
          title: "Happy Hour",
          description:
            "It's every Friday at Isamilo lodge pool areas, come and chill with friends and family. Enjoy snacks & 25% off on local beers.",
          image: require("@/assets/isamilo_lodge_gallery_35.jpeg"),
        },
        {
          title: "Weddings and Parties",
          description:
            "Our halls are fully equipped and can host up to 400 delegates while providing a quiet and comfortable atmosphere.",
          image: require("@/assets/events_4.jpg"),
        },
        {
          title: "Meetings and Conferences",
          description:
            "Flexible meeting space, up-to-date training tools, and modern equipment at your disposal.",
          image: require("@/assets/events_5.jpg"),
        },
        {
          title: "The Spa",
          description:
            "High-quality spa treatments delivered in a serene setting are the very heart of a quality spa experience.",
          image: require("@/assets/isamilo_lodge_gallery_37.jpeg"),
        },
      ],
      cta: "Book Now",
    };
  },

  methods: {
    booknow() {
      this.$router.push("booknow");
      window.scrollTo(0, 0);
    },
  },
};
</script>

