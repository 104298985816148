<template>
  <div id="rooms-section" class="my-6">
    <h3 class="text-center">
      {{ title }}
    </h3>

    <h2
      class="text-center"
      style="font-family: Playfair Display; font-size: 48px; line-height: 58px"
    >
      {{ subtitle }}
    </h2>
    <v-container grid-list-xs>
      <v-row justify="center" align="center" class="py-6">
        <div class="col-lg-5 mr-lg-6">
          <v-card tile elevation="8" class="pl-6 pt-6">
            <v-col>
              <h2
                style="
                  font-family: Playfair Display;
                  font-size: 40px;
                  font-weight: 700;
                  line-height: 58px;
                "
              >
                {{ room }}
              </h2>
              <p>{{ roomDescription }}</p>
              <v-row class="pl-4 py-4">
                <v-img contain max-width="120" :src="icons"></v-img>
              </v-row>

              <v-row>
                <v-col>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn
                      tile
                      elevation="0"
                      dark
                      large
                      color="#307E38"
                      class="text-capitalize"
                      style="
                        font-family: Playfair Display;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 0;
                      "
                      @click="goTo('rooms')"
                      >{{ cta }}</v-btn
                    >
                    <v-sheet color="secondary">
                      <v-icon dark class="pa-2">mdi-key</v-icon>
                    </v-sheet>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </div>

        <v-col class="col-lg-6">
          <v-carousel :show-arrows="false" cycle hide-delimiters>
            <v-carousel-item
              v-for="(img, i) in imgs"
              :key="i"
              :src="img"
              transition="fade-transition"
            ></v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </v-container>
    <v-img :src="pool"></v-img>
  </div>
</template>

<script>
export default {
  name: "RoomsSection",
  data() {
    return {
      title: "LUXURY ROOMS",
      subtitle: "Rooms & Suites",
      room: "Our Rooms",
      roomDescription:
        "Our rooms are spaciopus, and loaded with amenities to make your stay as comfortable and as luxurious as possible, along iwht our added services like, Room Service, the Pool, the Restaurant and more.",
      cta: "See Rooms",

      imgs: [
        require("@/assets/room_1.jpg"),
        require("@/assets/room_2.jpg"),
        require("@/assets/room_3.jpg"),
        require("@/assets/room_4.jpg"),
        require("@/assets/room_5.jpg"),
      ],
      icons: require("@/assets/room_icons.png"),
      pool: require("@/assets/pool.jpg"),
    };
  },

  methods: {
    goTo(path) {
      this.$router.push(path);
      window.scrollTo(0, 0);
    },
  },
};
</script>

