<template>
  <v-app-bar app color="white" flat hide-on-scroll>
    <router-link to="/">
      <v-img
        alt="Logo"
        class="shrink mt-1"
        contain
        min-width="100"
        src="@/assets/logo.png"
        width="140"
      />
    </router-link>

    <v-spacer></v-spacer>
    <v-app-bar-nav-icon
      v-show="$vuetify.breakpoint.mobile"
      color="primary"
      @click="showDrawer()"
    ></v-app-bar-nav-icon>
    <v-row justify="end" align="center" class="hidden-sm-and-down">
      <router-link to="/">
        <v-btn
          plain
          style="font-family: Spartan; font-size: 14px; line-height: 16px"
          >HOME</v-btn
        >
      </router-link>
      <router-link to="/rooms">
        <v-btn
          plain
          style="font-family: Spartan; font-size: 14px; line-height: 16px"
          >ROOMS</v-btn
        >
      </router-link>
      <router-link to="/events">
        <v-btn
          plain
          style="font-family: Spartan; font-size: 14px; line-height: 16px"
          >EVENTS</v-btn
        ></router-link
      >
      <router-link to="/tours">
        <v-btn
          plain
          style="font-family: Spartan; font-size: 14px; line-height: 16px"
          >TOURS</v-btn
        ></router-link
      >
      <router-link to="/about">
        <v-btn
          plain
          style="font-family: Spartan; font-size: 14px; line-height: 16px"
          >ABOUT</v-btn
        ></router-link
      >
      <router-link to="/gallery">
        <v-btn
          plain
          style="font-family: Spartan; font-size: 14px; line-height: 16px"
          >GALLERY</v-btn
        ></router-link
      >

      <router-link to="/booknow">
        <v-btn
          elevation="0"
          color="#307E38"
          tile
          dark
          x-large
          style="
            font-family: Spartan;
            font-size: 14px;
            line-height: 16px;
            font-weight: 700;
          "
          >BOOK NOW</v-btn
        ></router-link
      >
    </v-row>
  </v-app-bar>
</template>

<script>
export default {
  name: "Header",

  methods: {
    showDrawer() {
      console.log("at header clicked show drawer");
      this.$emit("open");
    },
  },
};
</script>

