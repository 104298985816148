<template>
  <v-carousel
    height="86vh"
    cycle
    :show-arrows="$vuetify.breakpoint.mobile ? false : true"
    show-arrows-on-hover
    hide-delimiter-background
    delimiter-icon="mdi-minus"
  >
    <v-carousel-item
      v-for="(slide, i) in slides"
      :key="i"
      :src="slide.src"
      gradient="to bottom, rgba(0,0,0,.8), rgba(0,0,0,.8)"
    >
      <v-row class="fill-height" align="center" justify="center">
        <v-col align="center" justify="center">
          <h3
            class="center--text white--text"
            style="font-family: Spartan; font-size: 14px; line-height: 16px"
          >
            {{ slide.title }}
          </h3>
          <h1
            style="
              font-family: Playfair Display;
              font-size: 64px;
              line-height: 78px;
            "
          >
            {{ slide.subtitle }}
          </h1>
          <router-link to="/rooms"
            ><v-btn outlined color="white" tile>BOOK NOW</v-btn></router-link
          >
        </v-col>
      </v-row>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "HomeHero",

  data() {
    return {
      slides: [
        {
          title: "OUR FAVORITE ROOMS",
          subtitle: "LUXURY RESORTS & SUITS",
          src: require("@/assets/room_1.jpg"),
        },
        {
          title: "OUR FAVORITE TOURS",
          subtitle: "HEART OF AFRICA",
          src: require("@/assets/home_6.jpg"),
        },
        {
          title: "OUR SPECIAL EVENTS",
          subtitle: "A SPECIAL MOMENT",
          src: require("@/assets/parties.jpg"),
        },
        {
          title: "OUR LOVELY STAFF",
          subtitle: "WE SERVE YOU",
          src: require("@/assets/staff.jpg"),
        },
        {
          title: "OUR POOL",
          subtitle: "FLOAT IN COMFORT",
          src: require("@/assets/pool.jpg"),
        },
        {
          title: "OUR CONFERENCE HALL",
          subtitle: "WEDDINGS, MEETINGS AND MORE",
          src: require("@/assets/conference_hall.jpg"),
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>