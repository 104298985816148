<template>
  <div id="tours-section" class="pt-6">
    <v-container grid-list-xs>
      <h3 class="text-center">{{ title }}</h3>
      <h2
        class="text-center"
        style="
          font-family: Playfair Display;
          font-size: 48px;
          line-height: 58px;
        "
      >
        {{ subtitle }}
      </h2>
      <v-row justify="center">
        <p class="text-center px-lg-6 col-lg-8">{{ description }}</p>
      </v-row>
      <v-row justify="center">
        <v-col
          v-for="tour in tours"
          :key="tour.title"
          :cols="$vuetify.breakpoint.mobile ? 12 : tour.flex"
        >
          <v-card tile elevation="0" @click="goToTours">
            <v-img
              :src="tour.image"
              height="400"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            >
              <v-card-title
                v-text="tour.title"
                style="
                  font-family: Playfair Display;
                  font-size: 28px;
                  font-weight: 700;
                "
              >
              </v-card-title>
              <v-card-text
                v-text="tour.description"
                style="font-family: Spartan; font-size: 16px"
              >
              </v-card-text>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ToursSection",
  data() {
    return {
      title: "DISCOVER THE MAGIC OF MWANZA",
      subtitle: "Our Tours",
      description:
        "Discover the magic of Mwanza with a variety of promotional packages with special rates offered by the lodge.",

      tours: [
        {
          title: "City Tour",
          description: "A city tour to the best attractions in the city.",
          flex: 6,
          image: require("@/assets/home_4.jpg"),
        },
        {
          title: "Fishing Trip / Boat Ride",
          description:
            "A cruise at Lake victoria. And getting Sato and Sangara.",
          flex: 6,
          image: require("@/assets/home_5.jpg"),
        },
        {
          title: "Game Drive at Serengeti National Park",
          description:
            "Come and see the annual migration of wildebeest and zebra. It is more than what you see on National Geographic.",
          flex: 12,
          image: require("@/assets/home_6.jpg"),
        },
      ],
    };
  },

  methods: {
    goToTours() {
      this.$router.push("tours");
      window.scrollTo(0, 0);
    },
  },
};
</script>