<template>
  <div id="booknow">
    <Hero :title="title" :subtitle="subtitle" :src="src" />
    <v-container grid-list-xs class="my-6">
      <v-row justify="center" align="center">
        <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 6">
          <form v-if="!showSuccessMessage">
            <v-text-field v-model="name" label="Name" required></v-text-field>
            <v-text-field v-model="phone" label="Phone" required></v-text-field>
            <v-text-field
              v-model="email"
              label="E-mail"
              required
            ></v-text-field>
            <v-select
              v-model="select"
              :items="items"
              label="Select An Item"
              required
            ></v-select>
            <v-select
              v-if="select === 'Book A Room'"
              v-model="room"
              :items="rooms"
              label="Select An Room"
              required
            ></v-select>

            <v-row v-if="select === 'Book A Room' && room" justify="center">
              <v-col cols="12" sm="6">
                <v-date-picker v-model="dates" range></v-date-picker>
              </v-col>
              <!-- <v-col cols="12" sm="6">
                <v-text-field
                  v-model="dateRangeText"
                  label="Date range"
                  prepend-icon="mdi-calendar"
                  readonly
                >
                </v-text-field>
                model: {{ dates }}
              </v-col> -->
            </v-row>
            <v-textarea
              v-model="message"
              name="message"
              label="Message"
              hint="Please enter your message"
            ></v-textarea>

            <v-btn
              tile
              accent
              dark
              elevation="0"
              :loading="loading"
              color="primary"
              style="
                font-family: Spartan;
                font-size: 14px;
                font-weight: 700;
                line-height: 16px;
              "
              class="mr-4"
              @click="submit"
            >
              submit
            </v-btn>
          </form>
          <p v-if="showSuccessMessage" class="primary--text text-center">
            {{ successMessage }}
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import emailjs from "emailjs-com";
import Hero from "@/components/layouts/Hero.vue";
export default {
  name: "Booknow",
  components: { Hero },
  data() {
    return {
      title: "KARIBU",
      subtitle: "Book Now",
      src: require("@/assets/sitting.jpg"),
      name: "",
      email: "",
      phone: "",
      select: null,
      room: null,
      rooms: [
        "Family Suit",
        "Single Deluxe",
        "Deluxe Twin Beds",
        "Double Deluxe Room",
        "Executive Suite",
      ],
      items: ["Book A Room", "Book A Tour", "Book An Event", "Book A Safari"],
      message: "",
      showSuccessMessage: false,
      loading: false,
      successMessage:
        "Your Message has been successfully sent. We will be contacting you shortly.",
      dates: [this.today, this.tomorrow],
    };
  },
  methods: {
    submit() {
      this.loading = true;
      let fullMessage = `${this.message} \n`;
      if (this.select === "Book A Room") {
        fullMessage = `${fullMessage} \n ${this.room} \n ${this.dateRangeText}`;
      }

      emailjs
        .send("service_vxzznep", "template_hi63p9d", {
          from_name: this.name,
          to_name: "Isamilo Lodge",
          phone: this.phone,
          service: this.select,
          message: fullMessage,
          reply_to: "info@isamilolodge.com",
        })
        .then((response) => {
          if (response.status == 200) {
            console.log("email sent");
            this.showSuccessMessage = true;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    today() {
      let today = new Date();
      let year = today.getFullYear();
      let month = today.getMonth();
      let day = today.getDate();
      return `${year}-${month}-${day}`;
    },
    tomorrow() {
      let ms = new Date().getTime() + 86400000 * 4;
      let tomorrow = new Date(ms);
      console.log(tomorrow.toDateString());
      let year = tomorrow.getFullYear();
      let month = tomorrow.getMonth();
      let day = tomorrow.getDate();
      return `${year}-${month}-${day}`;
    },
  },
};
</script>

<style>
</style>