<template>
  <div id="events-section" class="pt-12">
    <h3 class="text-center">{{ title }}</h3>
    <h2
      class="text-center"
      style="font-family: Playfair Display; font-size: 48px; line-height: 58px"
    >
      {{ subtitle }}
    </h2>

    <v-row justify="center">
      <p class="text-center col-lg-8">{{ description }}</p>
    </v-row>
    <v-img :src="comference"></v-img>
    <v-container grid-list-xs>
      <v-row justify="center">
        <v-col
          v-for="event in events"
          :key="event.title"
          :cols="$vuetify.breakpoint.mobile ? 12 : 4"
        >
          <v-card tile elevation="0" @click="goToEvents">
            <v-img
              :src="event.image"
              height="400"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            >
              <v-card-title
                v-text="event.title"
                style="
                  font-family: Playfair Display;
                  font-size: 28px;
                  font-weight: 700;
                "
              >
              </v-card-title>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "EventsSection",
  data() {
    return {
      title: "THE BEST OFFER",
      subtitle: "Special Events",
      description:
        "Discover the magic of Mwanza with a variety of promotional packages with special rates offered by the lodge.",
      comference: require("@/assets/conference_hall_2.jpg"),

      events: [
        {
          title: "Parties",

          image: require("@/assets/parties.jpg"),
        },
        {
          title: "Weddings",

          image: require("@/assets/weddings.jpg"),
        },
        {
          title: "Graduation",

          image: require("@/assets/graduation.jpg"),
        },
      ],
    };
  },

  methods: {
    goToEvents() {
      this.$router.push("events");
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style>
</style>