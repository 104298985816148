<template>
  <div id="home">
    <HomeHero />
    <Welcome />
    <RoomsSection />
    <ToursSection />
    <EventsSection />
  </div>
</template>

<script>
import HomeHero from "@/views/home/HomeHero.vue";
import Welcome from "@/views/home/Welcome.vue";
import RoomsSection from "@/views/home/RoomsSection.vue";
import ToursSection from "@/views/home/ToursSection.vue";
import EventsSection from "@/views/home/EventsSection.vue";

export default {
  name: "Home",

  components: {
    HomeHero,
    Welcome,
    RoomsSection,
    ToursSection,
    EventsSection,
  },
};
</script>
