<template>
  <div id="gallery">
    <Hero :title="title" :subtitle="subtitle" :src="src" />
    <v-container grid-list-xs>
      <h2
        style="
          font-family: Playfair Display;
          font-size: 48px;
          line-height: 78px;
        "
      >
        {{ sectionTitle }}
      </h2>
    </v-container>
    <v-container grid-list-xs>
      <v-row justify="center">
        <v-col
          v-for="(image, i) in images"
          :key="i"
          :cols="$vuetify.breakpoint.mobile ? 12 : 4"
        >
          <v-img :src="image" aspect-ratio="1" class="grey lighten-2">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Hero from "@/components/layouts/Hero.vue";
export default {
  name: "Gallery",
  components: { Hero },

  data() {
    return {
      title: "VIEW MORE OF ISAMILO LODGE",
      subtitle: "GALLERY",
      src: require("@/assets/room_4.jpg"),
      sectionTitle: "See More Of Us",
      images: [
        require("@/assets/home_1.jpg"),
        require("@/assets/home_2.jpg"),
        require("@/assets/home_3.jpg"),
        require("@/assets/home_4.jpg"),
        require("@/assets/home_5.jpg"),
        require("@/assets/home_6.jpg"),
        require("@/assets/parties.jpg"),
        require("@/assets/events_1.jpg"),
        require("@/assets/events_2.jpg"),
        require("@/assets/events_3.jpg"),
        require("@/assets/events_4.jpg"),
        require("@/assets/events_5.jpg"),
        require("@/assets/room_1.jpg"),
        require("@/assets/room_2.jpg"),
        require("@/assets/room_3.jpg"),
        require("@/assets/room_4.jpg"),
        require("@/assets/room_5.jpg"),
        require("@/assets/tours_1.jpg"),
        require("@/assets/tours_2.jpg"),
        require("@/assets/tours_3.jpg"),
        require("@/assets/tours_4.jpg"),
        require("@/assets/tours_5.jpg"),
        require("@/assets/tours_6.jpg"),
        require("@/assets/tours_7.jpg"),
        require("@/assets/tours_8.jpg"),
        require("@/assets/isamilo_lodge_gallery_0.jpeg"),
        require("@/assets/isamilo_lodge_gallery_2.jpeg"),
        require("@/assets/isamilo_lodge_gallery_3.jpeg"),
        require("@/assets/isamilo_lodge_gallery_4.jpeg"),
        require("@/assets/isamilo_lodge_gallery_5.jpeg"),
        require("@/assets/isamilo_lodge_gallery_6.jpeg"),
        require("@/assets/isamilo_lodge_gallery_9.jpeg"),
        require("@/assets/isamilo_lodge_gallery_10.jpeg"),
        require("@/assets/isamilo_lodge_gallery_11.jpeg"),
        require("@/assets/isamilo_lodge_gallery_12.jpeg"),
        require("@/assets/isamilo_lodge_gallery_13.jpeg"),
        require("@/assets/isamilo_lodge_gallery_14.jpeg"),
        require("@/assets/isamilo_lodge_gallery_15.jpeg"),
        require("@/assets/isamilo_lodge_gallery_16.jpeg"),
        require("@/assets/isamilo_lodge_gallery_17.jpeg"),
        require("@/assets/isamilo_lodge_gallery_18.jpeg"),
        require("@/assets/isamilo_lodge_gallery_19.jpeg"),
        require("@/assets/isamilo_lodge_gallery_20.jpeg"),
        require("@/assets/isamilo_lodge_gallery_32.jpeg"),
        require("@/assets/isamilo_lodge_gallery_33.jpg"),
        require("@/assets/isamilo_lodge_gallery_34.jpg"),
        require("@/assets/isamilo_lodge_gallery_35.jpeg"),
        require("@/assets/isamilo_lodge_gallery_36.jpeg"),
        require("@/assets/isamilo_lodge_gallery_37.jpeg"),
        require("@/assets/isamilo_lodge_gallery_38.jpeg"),
        require("@/assets/isamilo_lodge_gallery_39.jpeg"),
        require("@/assets/isamilo_lodge_gallery_40.jpg"),
        require("@/assets/isamilo_lodge_gallery_41.jpg"),
        require("@/assets/isamilo_lodge_gallery_44.jpg"),
        require("@/assets/elephant.jpeg"),
        require("@/assets/pool.jpg"),
        require("@/assets/sitting.jpg"),
        require("@/assets/conference_hall.jpg"),
        require("@/assets/conference_hall_2.jpg"),
        require("@/assets/room_wide.jpg"),
      ],
    };
  },
  methods: {
    shuffleImages() {
      this.images.sort(() => Math.random() - 0.5);
    },
  },
  mounted() {
    this.shuffleImages();
  },
};
</script>

