<template>
  <v-footer padless dark>
    <v-card class="flex" flat tile>
      <v-card-text>
        <v-row justify="center">
          <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 3" class="mr-lg-6">
            <h4>
              {{ titleOne }}
            </h4>
            <p style="color: white">
              {{ description }}
            </p>
            <v-row class="my-6">
              <v-icon class="mx-2" @click="open('facebook')"
                >mdi-facebook</v-icon
              >
              <v-icon class="mx-2" @click="open('instagram')"
                >mdi-instagram</v-icon
              >
              <v-icon class="mx-2" @click="open('whatsapp')"
                >mdi-whatsapp</v-icon
              >
              <v-icon class="mx-2">mdi-tripadvisor</v-icon>
              <v-icon class="mx-2">mdi-bookingcom</v-icon>
              <v-icon class="mx-2">mdi-hotel</v-icon>
            </v-row>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 3">
            <h4>{{ userfulLinks }}</h4>
            <p style="color: white" @click="goTo('about')">{{ about }}</p>
            <p style="color: white" @click="goTo('rooms')">{{ rooms }}</p>
            <p style="color: white" @click="goTo('events')">{{ events }}</p>
            <p style="color: white" @click="goTo('tours')">{{ tours }}</p>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 3">
            <h4>{{ contact }}</h4>
            <p style="color: white" @click="open(phoneOne)">{{ phoneOne }}</p>
            <p style="color: white" @click="open(phoneOne)">{{ phoneTwo }}</p>
            <p style="color: white" @click="open(email)">{{ email }}</p>
            <p style="color: white">{{ address }}</p>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text align-center class="text-center" style="background: #307e38">
        <p
          style="
            font-family: Spartan;
            font-size: 16px;
            line-height: 28px;
            color: #fff;
          "
        >
          {{ copyright }}
        </p>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      titleOne: "Isamilo Lodge",
      description:
        "Feel at home, with the best hospitality and services from the best hotel.",

      copyright: "@Isamilo Lodge " + this.getYear() + ". All Rights Reserved.",
      userfulLinks: "Useful Links",
      about: "About Us",
      rooms: "Rooms",
      tours: "Tours",
      events: "Events",

      contact: "Contact",
      phoneOne: "+255 789 771 111",
      phoneTwo: "+255 756 771 111",
      email: "info@isamilolodge.com",

      address: "Mwanza, Tanzania",
      urls: {
        facebook: "https://www.facebook.com/Isamilo-Lodge-Spa-109220468039871",
        instagram: "https://www.instagram.com/isamilo_spa/",
        whatsapp: "https://wa.me/+255789771111",
        phone: "tel:",
        email: "mailto:",
      },
    };
  },
  methods: {
    getYear() {
      return new Date().getFullYear();
    },

    open(destination) {
      var url;
      switch (destination) {
        case "facebook":
          url = this.urls.facebook;
          break;
        case "instagram":
          url = this.urls.instagram;
          break;
        case "whatsapp":
          url = this.urls.whatsapp;
          break;
        case this.phoneOne:
          url = this.urls.phone + this.phontOne;
          break;
        case this.phoneTwo:
          url = this.urls.phone + this.phontTwo;
          break;
        case this.email:
          url = this.urls.email + this.email;
          break;
      }

      window.open(url);
    },

    goTo(path) {
      this.$router.push(path);
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>
h4 {
  color: #fff;
  font-family: Playfair Display;
  font-size: 28px;
  font-weight: 700;
  line-height: 60px;
}

p {
  font-family: Spartan;
  font-size: 16px;
  line-height: 28px;
}
</style>