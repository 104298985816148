<template>
  <div id="tours">
    <Hero :title="title" :subtitle="subtitle" :src="src" />

    <v-container grid-list-xs class="mt-6">
      <h2
        style="
          font-family: Playfair Display;
          font-size: 36px;
          line-height: 56px;
        "
      >
        {{ sectionTitle }}
      </h2>
      <p>{{ sectionSubtitle }}</p>

      <h2
        style="
          color: #307e38;
          font-family: Playfair Display;
          font-size: 24px;
          line-height: 62px;
        "
        class="mt-6"
      >
        {{ cityTourSectionTitle }}
      </h2>
      <v-row justify="center">
        <v-col
          v-for="cityTour in cityTours"
          :key="cityTour.title"
          :cols="$vuetify.breakpoint.mobile ? 12 : cityTour.flex"
        >
          <v-card tile elevation="8">
            <v-img :src="cityTour.image" height="400"> </v-img>
            <v-card-title
              primary-title
              style="
                font-family: Playfair Display;
                font-size: 24px;
                font-weight: 700;
                line-height: 42px;
              "
            >
              {{ cityTour.title }}
            </v-card-title>
            <v-card-text
              style="
                font-family: Spartan;
                font-size: 16px;
                color: #000;
                line-height: 32px;
              "
            >
              {{ cityTour.description }}
            </v-card-text>
            <v-btn
              block
              tile
              color="#307e38"
              class="text-center py-2 text-capitalize"
              style="
                color: #fff;
                font-family: Playfair Display;
                font-size: 20px;
                font-weight: 700;
                line-height: 29px;
              "
              @click="booknow"
              >{{ cta }}</v-btn
            >
          </v-card>
        </v-col>
      </v-row>

      <h2
        style="
          color: #307e38;
          font-family: Playfair Display;
          font-size: 24px;
          line-height: 62px;
        "
        class="mt-6"
      >
        {{ fishingTripsSectionTitle }}
      </h2>

      <v-row justify="center">
        <v-col
          v-for="fishingTrip in fishingTrips"
          :key="fishingTrip.title"
          :cols="$vuetify.breakpoint.mobile ? 12 : fishingTrip.flex"
        >
          <v-card tile elevation="8">
            <v-img :src="fishingTrip.image" height="400"> </v-img>
            <v-card-title
              primary-title
              style="
                font-family: Playfair Display;
                font-size: 24px;
                font-weight: 700;
                line-height: 42px;
              "
            >
              {{ fishingTrip.title }}
            </v-card-title>

            <v-btn
              block
              tile
              color="primary"
              class="text-center py-2 text-capitalize"
              style="
                color: #fff;
                font-family: Playfair Display;
                font-size: 20px;
                font-weight: 700;
                line-height: 29px;
              "
              @click="booknow"
              >{{ cta }}</v-btn
            >
          </v-card>
        </v-col>
      </v-row>

      <h2
        style="color: #307e38; font-family: Playfair Display; font-size: 24px"
        class="mt-6"
      >
        {{ gameDrivesSectionTitle }}
      </h2>

      <v-row justify="center">
        <v-col
          v-for="gameDrive in gameDrives"
          :key="gameDrive.title"
          :cols="$vuetify.breakpoint.mobile ? 12 : gameDrive.flex"
        >
          <v-card tile elevation="8">
            <v-img :src="gameDrive.image" height="400"> </v-img>
            <v-card-title
              primary-title
              style="
                font-family: Playfair Display;
                font-size: 24px;
                font-weight: 700;
                line-height: 42px;
              "
            >
              {{ gameDrive.title }}
            </v-card-title>
            <v-card-text
              style="
                font-family: Spartan;
                font-size: 16px;
                color: #000;
                line-height: 32px;
              "
            >
              {{ gameDrive.description }}
            </v-card-text>

            <v-btn
              block
              tile
              color="#307e38"
              class="text-center py-2 text-capitalize"
              style="
                color: #fff;
                font-family: Playfair Display;
                font-size: 20px;
                font-weight: 700;
                line-height: 29px;
              "
              @click="booknow"
              >{{ cta }}</v-btn
            >
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Hero from "@/components/layouts/Hero.vue";
export default {
  name: "Tours",
  components: { Hero },
  data() {
    return {
      title: "DISCOVER THE MAGIC OF MWANZA",
      subtitle: "OUR TOUR PACKAGES",
      src: require("@/assets/elephant.jpeg"),
      cta: "Book Now",

      sectionTitle: "THE MAGIC OF MWANZA",
      sectionSubtitle:
        "Discover the magic of Mwanza with a variety of promotional packages with special rates offered by the lodge which are; Mwanza city tour to bismark rock, Sukuma museum at Bujora, saanane Island and Tunza beach sunset. Fishing trip and boat ride at Lake Victoria. One or two days game drive at Serengeti National park.",
      sectionImage: require("@/assets/tours_1.jpg"),

      cityTourSectionTitle: "City Tour",
      fishingTripsSectionTitle: "Fishing Trip / Boat Ride",
      gameDrivesSectionTitle: "Game Drive at Serengeti National Park",

      cityTours: [
        {
          title: "Bismark Rock",
          description:
            "Bismark Rock is one of Big attraction in lake Victoria which situated in Northern Tanzania. Lake Victoria is the Source of the famous Nile River, Lake Victoria is located in East Africa. Discovered by John Speke in 1858 and named after the then Queen (Victoria) who was at the reins of England that time. The lake rests between three countries namely, Kenya, Uganda and Tanzania.",
          image: require("@/assets/tours_2.jpg"),
          flex: 6,
        },
        {
          title: "Saa Nane Island",
          description:
            "Saa Nane Island was named after its previous owner, Mzee Saanane Chawandi, a fisherman who turned into a farmer and later shifted to another island (after being compensated) to pave the way for conservation efforts in the early 1960’s. The Tanzania government officially owned the island in 1964 as the first ever government owned zoo. Originally its territory measured some 0.7 square kilometers comprising both dry land and water.",
          image: require("@/assets/tours_3.jpg"),
          flex: 6,
        },
        {
          title: "Bujora Sukuma Museum",
          description:
            "The Bujora Cultural Centre and Sukuma Museum in Kisesa, are historical institutions founded for the education and support of Sukuma culture. The arts of the Sukuma culture are among the richest in East Africa. As the Sukuma people are the largest cultural group in Tanzania, the Sukuma culture is dispersed throughout the country. The heart of Usukuma is in the Lake Zone of Mwanza, Shinyanga and the Mara regions where the legacy of a rich art tradition is now maintained.",
          image: require("@/assets/tours_4.jpg"),
          flex: 12,
        },
      ],
      fishingTrips: [
        {
          title: "Fishing Trip / Boat Ride",
          image: require("@/assets/tours_6.jpg"),
          flex: 6,
        },
        {
          title: "Boating",
          image: require("@/assets/tours_5.jpg"),
          flex: 6,
        },
        {
          title: "Lake Victoria Boats",
          image: require("@/assets/tours_7.jpg"),
          flex: 12,
        },
      ],
      gameDrives: [
        {
          title: "Serengeti Safaris",
          description:
            "Serengeti National Park, in northern Tanzania, is known for its massive annual migration of wildebeest and zebra. Seeking new pasture, the herds move north from their breeding grounds in the grassy southern plains. Many cross the marshy western corridor’s crocodile-infested Grumeti River. Others veer northeast to the Lobo Hills, home to black eagles. Black rhinos inhabit the granite outcrops of the Moru Kopjes.",
          image: require("@/assets/tours_8.jpg"),
        },
      ],
    };
  },

  methods: {
    booknow() {
      this.$router.push("booknow");
      window.scrollTo(0, 0);
    },
  },
};
</script>

