import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/Home.vue'
import About from '../views/about/About.vue'
import Rooms from '../views/rooms/Rooms.vue'
import Events from '../views/events/Events.vue'
import Tours from '../views/tours/Tours.vue'
import Gallery from '../views/gallery/Gallery.vue'
import Booknow from '../views/booknow/Booknow.vue'
import { init } from 'emailjs-com';

init("user_MU7XFGf0frCuLR4iZVGdK");

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/rooms',
        name: 'Rooms',
        component: Rooms
    },
    {
        path: '/events',
        name: 'Events',
        component: Events
    },
    {
        path: '/tours',
        name: 'Tours',
        component: Tours
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
    {
        path: '/gallery',
        name: 'Gallery',
        component: Gallery
    },
    {
        path: '/booknow',
        name: 'Booknow',
        component: Booknow
    },
    { path: "*", component: Home }

]

const router = new VueRouter({
    routes,
    mode:'history'
})

export default router