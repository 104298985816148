<template>
  <v-img
    height="50vh"
    :src="src"
    class="py-auto"
    gradient="to bottom, rgba(0,0,0,.8), rgba(0,0,0,.8)"
  >
    <v-row class="fill-height" align="center" justify="center">
      <v-col align="center" justify="center">
        <h3
          class="center--text white--text"
          style="font-family: Spartan; font-size: 14px; line-height: 16px"
        >
          {{ title }}
        </h3>
        <h1
          style="
            font-family: Playfair Display;
            font-size: 64px;
            line-height: 78px;
          "
        >
          {{ subtitle }}
        </h1>
      </v-col>
    </v-row>
  </v-img>
</template>

<script>
export default {
  name: "Hero",
  props: {
    title: String,
    subtitle: String,
    src: String,
  },
};
</script>

