<template>
  <div id="rooms">
    <Hero :title="title" :subtitle="subtitle" :src="src" />

    <v-container fluid>
      <v-row justify="center">
        <v-col
          :cols="$vuetify.breakpoint.mobile ? 12 : room.flex"
          v-for="(room, i) in rooms"
          :key="i"
        >
          <v-card tile class="ma-4" elevation="12" to="/booknow">
            <v-img height="400" :src="room.img"></v-img>
            <v-card-title
              primary-title
              style="
                font-family: 'Playfair Display';
                font-size: 28px;
                font-weight: 700;
                line-height: 58px;
              "
            >
              {{ room.title }}
            </v-card-title>
            <v-card-text
              style="font-family: Spartan; font-size: 18px; line-height: 32px"
            >
              {{ room.description }}
            </v-card-text>

            <v-row class="ml-4 pr-3 align-center">
              <v-img
                max-width="152"
                class="ma-4"
                src="@/assets/room_icons.png"
              ></v-img>
              <v-spacer></v-spacer>

              <v-btn
                tile
                large
                elevation="0"
                dark
                height="68px"
                color="#307E38"
                class="text-capitalize"
                style="
                  font-family: Playfair Display;
                  font-size: 16px;
                  font-weight: 700;
                  line-height: 0;
                "
                >Book Now</v-btn
              >
              <!-- <v-sheet color="secondary" height="68px" width=" 72px"> </v-sheet> -->
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Hero from "@/components/layouts/Hero.vue";
export default {
  name: "Rooms",
  components: { Hero },
  data() {
    return {
      src: require("@/assets/home_1.jpg"),
      title: "OUR FAVORITE ROOMS",
      subtitle: "OUR ROOMS & SUITS",

      rooms: [
        {
          id: 0,
          title: "Family Suit",
          description:
            "The family suite is carefully designed to host family members. It is set up to be your home away from home. Fully integrated with in-house facilities such as the air conditioner, spacious bathrooms, and comfortable beds you will find the family suite up to your liking.",

          img: require("@/assets/room_1.jpg"),
          flex: 10,
        },
        {
          id: 1,
          title: "Single Deluxe",
          description:
            "The Deluxe Single Room is ideal for travelers looking for a fully renovated room with upgraded furnishings and decor, and a private full bathroom",

          img: require("@/assets/room_6.jpg"),
          flex: 5,
        },
        {
          id: 1,
          title: "Deluxe Twin Beds",
          description:
            "Deluxe Twin rooms feature modern and freshly styled bathrooms with a walk-in rain shower. The rooms have 2 separate beds have enough space for the pair",

          img: require("@/assets/room_2.jpg"),
          flex: 5,
        },
        {
          id: 2,
          title: "Double Deluxe Room",
          description:
            "A spacious answer to the common Business or Casual travelers' accommodation, the Deluxe Double/Double rooms include comfortable double beds and a refrigerator among other convenient amenities.",

          img: require("@/assets/room_4.jpg"),
          flex: 5,
        },
        {
          id: 3,
          title: "Executive Suite",
          description:
            "The executive suite is perfect for businessmen and women, and top managers of companies because these suites are combining together a fully serviced office, and a luxurious hotel room together.",

          img: require("@/assets/room_5.jpg"),
          flex: 5,
        },
      ],
    };
  },
  methods: {
    booknow() {
      this.$router.push("booknow");
      window.scrollTo(0, 0);
    },
  },
};
</script>

