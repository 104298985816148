<template>
  <div id="welcome-section">
    <v-container grid-list-xs>
      <v-row class="ma-6" justify="center" align="center">
        <v-col justify-center align-center class="col-lg-6 mr-lg-6">
          <h3>{{ title }}</h3>
          <h2
            style="
              font-family: Playfair Display;
              font-size: 48px;
              line-height: 58px;
            "
          >
            {{ subtitle }}
          </h2>

          <p>{{ description }}</p>

          <router-link to="/rooms"
            ><v-btn
              tile
              accent
              dark
              elevation="0"
              color="#EC1E2F"
              style="
                font-family: Spartan;
                font-size: 14px;
                font-weight: 700;
                line-height: 16px;
              "
              >{{ cta }}</v-btn
            ></router-link
          >
        </v-col>
        <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 5">
          <v-img :src="imgBig" contain centered> </v-img>
        </v-col>
      </v-row>
    </v-container>

    <v-img :src="entrance"></v-img>
  </div>
</template>

<script>
export default {
  name: "Welcome",

  data() {
    return {
      title: "THE HOME OF HOSPITALITY",
      subtitle: "Welcome To Isamilo Lodge & Spa",
      description:
        "Escape to a relaxing hill front retreat at Isamilo Lodge & Spa. The lodge offers; spectacular views, luxurious accommodation, International cuisine, SPA, stylish event venues, a Swimming pool, and beautiful gardens. Our luxurious lodge is the ideal destination for business and leisure while in Mwanza. The lodge is situated on the hills of Isamilo overlooking Lake Victoria and its countless Islands like Saanane Island. It is located approximately 8 km from Mwanza airport and 2 km from Mwanza city.",
      imgBig: require("@/assets/isamilo_lodge_gallery_16.jpeg"),
      cta: "BOOK NOW",
      entrance: require("@/assets/entrance.jpg"),
    };
  },
};
</script>
