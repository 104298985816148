<template>
  <v-app>
    <Header @open="open()" />
    <v-main>
      <router-view />
      <Drawer @close="close()" :showDrawer="showDrawer" />
    </v-main>
    <Footer />
    
  </v-app>
</template>

<script>
import Header from "@/components/layouts/Header.vue";
import Footer from "@/components/layouts/Footer.vue";
import Drawer from "@/components/layouts/Drawer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
    Drawer,
  },

  data() {
    return {
      showDrawer: false,
    };
  },
  methods: {
    open() {
      console.log("on open drawer");
      this.showDrawer = true;
    },
    close() {
      console.log("on open drawer");
      this.showDrawer = false;
    },
  },
};
</script>

<style >
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700;900&family=Spartan:wght@300;400&display=swap");
h1 {
  color: #fff;
  font-family: "PlayfairDisplay Black";
  font-size: 56px;
  line-height: 56px;
  text-align: center;
}
h2 {
  font-family: PlayfairDisplay;
  font-size: 36px;
  line-height: 36px;
}

p {
  color: #000;
  font-family: Spartan;
  font-size: 18px;
  line-height: 32px;
}

h3 {
  font-family: Spartan;
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
}
h4 {
  font-family: Playfair Display;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
}

a {
  text-decoration: none;
}
</style>
