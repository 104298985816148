<template>
  <div id="about">
    <Hero :title="title" :subtitle="subtitle" :src="src" />
    <v-container grid-list-xs class="pt-12">
      <v-row>
        <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 6">
          <h3 style="font-family: Spartan; font-size: 14px; line-height: 16px">
            {{ about }}
          </h3>
          <h2
            style="
              font-family: Playfair Display;
              font-size: 48px;
              line-height: 58px;
            "
            class="mb-4"
          >
            {{ knowUs }}
          </h2>
          <p>{{ description }}</p>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 6">
          <v-img :src="image" max-height="50vh"></v-img>
        </v-col>
      </v-row>
    </v-container>
    <v-img :src="room" class="my-6"></v-img>
    <Testimonials />
    <v-img :src="sitting" class="mt-6"></v-img>
  </div>
</template>


<script>
import Hero from "@/components/layouts/Hero.vue";
import Testimonials from "@/views/about/Testimonials.vue";
export default {
  name: "About",
  components: { Hero, Testimonials },
  data() {
    return {
      title: "GET TO KNOW US",
      subtitle: "ABOUT US",
      src: require("@/assets/events_3.jpg"),

      about: "ABOUT US",
      knowUs: "Get To Know Us",
      description:
        "Escape to a relaxing hill front retreat at Isamilo Lodge & Spa. The lodge offers; spectacular views, luxurious accommodation, International cuisine, SPA, stylish event venues, a Swimming pool, and beautiful gardens. Our luxurious lodge is the ideal destination for business and leisure while in Mwanza. The lodge is situated on the hills of Isamilo overlooking Lake Victoria and its countless Islands like Saanane Island. It is located approximately 8 km from Mwanza airport and 2 km from Mwanza city.",
      image: require("@/assets/home_2.jpg"),
      room: require("@/assets/room_wide.jpg"),
      sitting: require("@/assets/sitting.jpg"),
    };
  },
};
</script>
